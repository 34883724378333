<style>
.c-fade {
  transition: opacity .5s;
}
</style>

<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config.json";

import {mapState, mapGetters} from "vuex";

import simplebar from "simplebar-vue";

import {get_acsrf_token, with_acrf_token} from "@/methods";
import {HalfCircleSpinner} from "epic-spinners";


export default {
  page: {
    title: "Player Search",
    meta: [{ name: "description", content: appConfig.description }]
  },
  computed: {
    ...mapGetters([
      'getAccountName',
      'getAccountId'
    ])
  },
  components: {
    Layout,
    HalfCircleSpinner
  },
  methods: {
    async startGlobalQuery() {
      if(this.globalQuery.firstSearch) this.globalQuery.firstSearch = false;
      this.globalQuery.error = false;
      this.globalQuery.results = [];
      if(this.globalQuery.debounceTimer) clearTimeout(this.globalQuery.debounceTimer);
      this.globalQuery.inProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          identifier: this.globalQuery.identifier
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/global-query`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          if(data.status) {
            this.globalQuery.results = data.results;
            this.globalQuery.rows = data.results.length;
          } else this.globalQuery.error = true;
        } else {
          if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.action'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }

      this.globalQuery.inProgress = false;
    },
    async onGlobalQueryIdentifierChange(event) {
      if(event.keyCode === 13) {
        await this.startGlobalQuery();
        return;
      }
      // TODO: Disabled this for testing as auto-triggering the search is pretty counter intuitive
      // if(this.globalQuery.debounceTimer) clearTimeout(this.globalQuery.debounceTimer);
      // this.globalQuery.debounceTimer = setTimeout(this.startGlobalQuery, 1250);
    },
    onFiltered(filteredItems) {
      this.globalQuery.totalRows = filteredItems.length;
      this.globalQuery.currentPage = 1;
    },
  },
  created() {

  },
  data() {
    return {
      globalQuery: {
        firstSearch: true,
        error: false,
        debounceTimer: null,
        inProgress: false,
        identifier: null,
        results: [],
        // table
        filter: null,
        totalRows: 0,
        currentPage: 1,
        filterOn: [],
        sortBy: "cftools_id",
        sortDesc: true,
        fields: [
          {
            key: "user.cftools_id",
            sortable: true,
            label: this.$t('query.results.columns.cftools_id')
          },
          {
            key: "identifier",
            sortable: true,
            label: this.$t('query.results.columns.identifier')
          },
          {
            key: "profile",
            sortable: false,
            label: this.$t('query.results.columns.profile')
          }
        ]
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex align-items-center justify-content-between">
      <h4 class="mb-0 font-size-18">
        Player Search
      </h4>
      <div class="page-title-right bv-d-xs-down-none"><ol class="breadcrumb m-0">
        <li class="breadcrumb-item">
          <router-link to="/">
            CFTools Cloud
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <span aria-current="location">
            Player Search
          </span>
        </li>
      </ol>
      </div>
    </div>
    <div class="row d-flex"></div>
    <div>
      <template>
        <div class="row justify-content-center mb-4 c-fade" v-if="globalQuery.firstSearch">
          <div class="col-lg-6 col-sm-12 text-center">
            <h3 class="mb-1">
              {{ $t('query.title') }}
            </h3>
            <h4 class="mb-4 text-muted">
              {{ $t('query.description') }}
            </h4>
          </div>
        </div>
        <div class="row justify-content-center c-fade">
          <div class="col-lg-6 col-sm-12">
            <div class="form-group m-0">
              <div class="input-group input-group-lg">
                <input
                    size="lg"
                    type="text"
                    class="form-control input-lg"
                    :placeholder="$t('query.placeholder')"
                    v-model="globalQuery.identifier"
                    v-on:keyup="onGlobalQueryIdentifierChange"
                    :disabled="globalQuery.inProgress"
                    :class="{'disabled': globalQuery.inProgress, 'is-invalid': globalQuery.error}"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" v-on:click="startGlobalQuery">
                    <template v-if="globalQuery.inProgress">
                      <half-circle-spinner
                          :animation-duration="1200"
                          :size="16"
                          class="align-middle"
                      />
                    </template>
                    <template v-else>
                      <i class="fad fa-search"></i>
                    </template>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="row justify-content-center mt-4" v-if="!globalQuery.firstSearch">
        <div class="col-lg-6 col-sm-12 card card-body">
        <template v-if="globalQuery.results.length === 0">
          <template v-if="globalQuery.inProgress">
            <half-circle-spinner
                :animation-duration="1200"
                :size="46"
                class="align-middle"
            />
          </template>
          <template v-else>
            <div class="row">
              <div class="col">
                <h4 class="text-center text-uppercase text-muted mt-4 mb-4">
                  {{ $t('query.results.none') }}
                </h4>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="row">
            <div class="col-lg-12 col-sm-12">
              <div id="tickets-table_filter" class="dataTables_filter">
                <label class="d-inline-flex align-items-center">
                  {{ $t('query.results.refine') }}:
                  <b-form-input v-model="globalQuery.filter" type="search" class="mw-100 form-control form-control-sm ml-2"></b-form-input>
                </label>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <b-table :items="globalQuery.results" :fields="globalQuery.fields" responsive="sm" :per-page="10" :current-page="globalQuery.currentPage" :sort-by.sync="globalQuery.sortBy" :sort-desc.sync="globalQuery.sortDesc" :filter="globalQuery.filter" :filter-included-fields="globalQuery.filterOn" @filtered="onFiltered">
              <template #cell(cftools_id)="data">
                <b>{{ data.item.cftools_id }}</b>
              </template>
              <template #cell(identifier)="data">
                <b>{{ data.item.identifier }}</b>
              </template>
              <template #cell(profile)="data">
                <router-link :to="{name: 'profile', params: {cftools_id: data.item.user.cftools_id}}">
                  <img
                      :src="data.item.user.avatar"
                      alt="profile picture"
                      class="rounded-circle avatar-xs"
                      v-if="data.item.user.avatar"
                      target="_blank"
                  >
                  {{ data.item.user.display_name }}
                  <small>
                    <i class="fal fa-external-link-alt" />
                  </small>
                </router-link>
              </template>
            </b-table>
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <b-pagination v-model="globalQuery.currentPage" :total-rows="globalQuery.rows" :per-page="10"></b-pagination>
              </ul>
            </div>
          </div>
          </template>
        </div>
      </div>
    </div>
  </Layout>
</template>